
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("autoskola-beran/app", function(){ return i("autoskola-beran/app.ts");});
d("autoskola-beran/config/environment", function(){ return i("autoskola-beran/config/environment.js");});
d("autoskola-beran/router", function(){ return i("autoskola-beran/router.ts");});
d("autoskola-beran/services/page-title", function(){ return i("autoskola-beran/services/page-title.js");});
d("autoskola-beran/instance-initializers/clear-double-boot", function(){ return i("autoskola-beran/instance-initializers/clear-double-boot.js");});
d("autoskola-beran/locations/none", function(){ return i("autoskola-beran/locations/none.js");});
d("autoskola-beran/services/fastboot", function(){ return i("autoskola-beran/services/fastboot.js");});
d("autoskola-beran/component-managers/glimmer", function(){ return i("autoskola-beran/component-managers/glimmer.js");});
d("autoskola-beran/initializers/app-version", function(){ return i("autoskola-beran/initializers/app-version.js");});
d("autoskola-beran/templates/index", function(){ return i("autoskola-beran/templates/index.ts");});
d("autoskola-beran/controllers/index", function(){ return i("autoskola-beran/controllers/index.ts");});
d("autoskola-beran/templates/application", function(){ return i("autoskola-beran/templates/application.ts");});
d("autoskola-beran/routes/application", function(){ return i("autoskola-beran/routes/application.ts");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("autoskola-beran/initializers/ajax", function(){ return i("autoskola-beran/initializers/ajax.js");});
d("autoskola-beran/initializers/error-handler", function(){ return i("autoskola-beran/initializers/error-handler.js");});
  }





if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('autoskola-beran/app')['default'].create({"name":"autoskola-beran","version":"0.0.0+67631e18"});
  }
}


